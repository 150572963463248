import { createApp } from "vue";
import App from "./App.vue";

import { createI18n } from "vue-i18n";
import pt from "./locales/pt";
import en from "./locales/en";

import router from "./router";

import vueHeadful from "vue-headful";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

/* import util function */
import { startLocale } from "@/utils/startLocale";

library.add(faWhatsapp);

const i18n = createI18n({
  locale: startLocale(),
  fallbackLocale: "en",
  messages: {
    pt,
    en,
  },
});

const app = createApp(App);

app.use(i18n);
app.use(router);
app.component("vue-headful", vueHeadful);
app.component("font-awesome-icon", FontAwesomeIcon);

app.mount("#app");
