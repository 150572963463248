import { createRouter, createWebHistory } from "vue-router";
import { defineAsyncComponent } from "vue";

/* istanbul ignore next */
const IndexPage = defineAsyncComponent(() => import("./views/IndexPage.vue"));
/* istanbul ignore next */
const ServiceDescription = defineAsyncComponent(() =>
  import("./views/ServiceDescription.vue")
);
/* istanbul ignore next */
const CaseDescription = defineAsyncComponent(() =>
  import("./views/CaseDescription.vue")
);
/* istanbul ignore next */
const PageNotFound = defineAsyncComponent(() =>
  import("./views/PageNotFound.vue")
);
/* istanbul ignore next */
const ContactResponse = defineAsyncComponent(() =>
  import("./views/ContactResponse.vue")
);
/* istanbul ignore next */
const TechnicalAdvice = defineAsyncComponent(() =>
  import("./views/TechnicalAdvice.vue")
);

const routes = [
  // NOTICE: After adding a new route here, remeber to also list it for prerender in vue.config.js
  { path: "/", component: IndexPage, name: "IndexPage" },
  {
    path: "/services/prototyping",
    component: ServiceDescription,
    props: { service: "prototyping" },
    name: "PrototypingServiceDescription",
  },
  {
    path: "/services/development",
    component: ServiceDescription,
    props: { service: "development" },
    name: "DevelopmentServiceDescription",
  },
  {
    path: "/services/maintenance",
    component: ServiceDescription,
    props: { service: "maintenance" },
    name: "MaintenanceServiceDescription",
  },
  {
    path: "/services/deployment",
    component: ServiceDescription,
    props: { service: "deployment" },
    name: "DeploymentServiceDescription",
  },
  {
    path: "/services/monitoring",
    component: ServiceDescription,
    props: { service: "monitoring" },
    name: "MonitoringServiceDescription",
  },
  {
    path: "/services/technical_analysis",
    component: ServiceDescription,
    props: { service: "technical_analysis" },
    name: "TechnicalAnalysisServiceDescription",
  },
  {
    path: "/services/bug_fixes",
    component: ServiceDescription,
    props: { service: "bug_fixes" },
    name: "BugFixingServiceDescription",
  },
  {
    path: "/services/improvements",
    component: ServiceDescription,
    props: { service: "improvements" },
    name: "ImprovementsServiceDescription",
  },
  {
    path: "/services/features",
    component: ServiceDescription,
    props: { service: "features" },
    name: "NewFeaturesServiceDescription",
  },
  {
    path: "/cases/kinoforum",
    component: CaseDescription,
    props: { caseDescription: "kinoforum" },
    name: "KinoforumCaseDescription",
  },
  {
    path: "/cases/ampere",
    component: CaseDescription,
    props: { caseDescription: "ampere" },
    name: "AmpereCaseDescription",
  },
  {
    path: "/cases/vetor",
    component: CaseDescription,
    props: { caseDescription: "vetor" },
    name: "VetorCaseDescription",
  },
  {
    path: "/contact/success",
    component: ContactResponse,
    props: { status: "success" },
    name: "SuccessfulContact",
  },
  {
    path: "/contact/failed",
    component: ContactResponse,
    props: { status: "failed" },
    name: "FailedContact",
  },
  {
    path: "/technical-advice",
    component: TechnicalAdvice,
    name: "TechnicalAdvice",
  },
  {
    path: "/:pathMatch(.*)*",
    component: PageNotFound,
    name: "PageNotFound",
  },
];

/* istanbul ignore next */
const scrollBehavior = (to, _from, _savedPosition) => {
  if (to.hash) {
    return {
      el: to.hash,
    };
  }
};

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior,
});

export default router;
