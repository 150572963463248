export default {
  hello: "Oi",
  components: {
    navbar: {
      about: "sobre",
      services: "serviços",
      projects: "projetos",
      team: "nosso time",
      contact: "contato",
    },
    cover: {
      concept_phrase_i: "Construindo software",
      concept_phrase_ii: "entregando experiências digitais",
    },
    about: {
      title: "sobre nós",
      description_i:
        "Somos a empresa de consultoria em TI e desenvolvimento de produtos tecnológicos que vai expandir e transformar o seu negócio com um pacote completo de serviços personalizado, de excelência e alinhado com as necessidades do mercado.",
      description_ii:
        "Criação de sites, aplicativos, sistemas e software em geral, com foco em uma interação simples, segura, flexível e eficiente de todos os portes e setores. Além de manutenções, mudanças e adaptações preventivas para otimizar garantir a qualidade.",
    },
    principles: {
      title: "nossos valores",
      excellence_title: "Excelência e Praticidade",
      excellence_text:
        "Entregamos produtos digitais práticos, de qualidade, com garantia.",
      commitment_title: "Comprometimento e Segurança",
      commitment_text:
        "Nos engajamos e envolvemos ao máximo para honrar as entregas, com segurança e superando as expectativas do serviço.",
      colab_work_title: "Colaboração e Criatividade",
      colab_work_text:
        "Participamos de todas as etapas do projeto, descobrindo junto aos clientes as melhores soluções tecnológicos para seus objetivos.",
    },
    services: {
      title: "serviços",
      description:
        "Oferecemos um pacote completo para o software dos nossos clientes, seja sistemas internos, externos, aplicativos, sites ou outras possibilidades. Prestamos todo o suporte desde a concepção e desenvolvimento da ideia até a implantação nos servidores o, monitoramento da estabilidade, manutenções e evoluções posteriores.",
      development: "desenvolvimento",
      prototyping: "criação de protótipos",
      deployment: "entregas automatizadas",
      maintenance: "manutenção",
      monitoring: "monitoramento",
      technical_analysis: "análise técnica",
      bug_fixes: "correção de erros",
      improvements: "melhorias",
      features: "novas funcionalidades",
    },
    customers: {
      title: "quem já transformamos",
    },
    cases: {
      title: "projetos de destaque",
      kinoforum: "Sistema de gerenciamento do acervo de filmes",
      vetor:
        "Gerenciamento e desenvolvimento dos sistemas que envolvem seleção de pessoas para atuação em governos",
      ampere: "Interface gráfica focada em experiência do usuário",
    },
    caseDescriptionSection: {
      challenge: {
        title: "Desafio",
        kinoforum:
          "Assumir a base de código iniciada por outra equipe em 2011 para corrigir erros frequentes, atualizar as tecnologias em uso, reduzir custos de infraestrutura e adicionar novas funcionalidades para suportar o Festival Internacional de Curta Metragens de São Paulo.",
        vetor:
          "Cuidar da infraestrutura, monitorar recursos, manter tudo em ordem, adicionar funcionalidades novas e criar sistemas dentro do seu ambiente empresarial.",
        ampere: `Partindo de um sistema atual e sendo já utilizado pelos usuários há anos, criar uma nova interface gráfica que mantenha o que os usuários já estão habituados, mas também traga melhorias de experiência como um todo:<ul>
          <li>visual</li>
          <li>facilidade de uso</li>
          <li>velocidade de carregamento</li>
          <li>E assim por diante</li>
        </ul>`,
      },
      results: {
        title: "Resultados",
        kinoforum:
          "O trabalho de manutenção de sistemas maduros é um esforço contínuo. Desde 2019 trabalhamos com o Kinofórum e hoje podemos dizer que temos um sistema estável, atualizado e com custos de infraestrutura em nuvem dentro do mínimo necessário para seu bom funcionamento.",
        vetor: `Ao longo do tempo em que trabalhamos com este cliente, fizemos consideráveis avanços no seu sistema entre os quais destacam-se os pontos mencionados<ul>
          <li>Lançamos 3 novos produtos internos que auxiliam na seleção de candidatas</li>
          <li>Otimizamos recursos de infraestrutura, e isto reduziu significativaments de custos</li>
          <li>Alteramos alguns fluxos</li>
          <li>E corrigimos erros, reduzindo significativamente os tickets de suporte, aliviando a operação e trazendo mais estabilidade às plataformas</li>
        </ul>`,
        ampere:
          "Com esse processo de reformulação proporcionamos à Ampere e seus clientes não apenas melhor experiência para as funcionalidades existentes, mas também a introdução de novas conforme foram priorizadas. O trabalho seguirá firme para que outras novas funcionalidades já planejadas também se concretizem.",
      },
      solution: {
        title: "Solução",
        kinoforum: `Realizamos um processo gradual de melhorias estruturais alinhadas com entregas de novas funcionalidades que atendem necessidades do negócio.
          <br /> <br />
          Esse processo aliou criação de testes automatizados para garantir que problemas solucionados não regressem e que novas funcionalidades permaneçam funcionando com análise completa de todos os gastos com infraestrutura e, para garantir a segurança dos dados e estabilidade do sistema, atualização gradual de todas as tecnologias utilizadas.`,
        vetor: `Para atender às demandas do nosso cliente, tomamos decisões no sentido de prover:<ul>
          <li>Automatização de processos repetitivos</li>
          <li>Monitoramento sistemático de recursos e falhas</li>
          <li>Dinâmicas para entendimento de novos requisitos tanto de produtos já existentes quanto de novos produtos e implementação da infraestrutura</li>
          <li>Modelagem do banco de dados</li>
          <li>Back end e front end</li>
        </ul>`,
        ampere: `Em parceria com uma designer de experiência do usuário que realizou entrevistas com usuários de todos os produtos oferecidos pelo sistema combinando com os pedidos do próprio time da Ampere, todas as telas foram reformuladas, apresentadas e ajustadas de acordo com seu retorno até serem consideradas prontas para o desenvolvimento.
          <br /> <br />
          Conforme as novas telas são aprovadas nosso time de desenvolvimento já mapeia para o time da Ampere quais mudanças são necessárias nos formatos de dados que fornecem (APIs) e prontamente iniciamos o desenvolvimento e assim que pronto a entrega em ambiente de homologação para que a última revisão possa ser feita pela equipe da Ampere e a designer.`,
      },
    },
    caseDescriptionTechnologies: {
      title: "Utilizamos o que há de mais moderno na tecnologia:",
    },
    team: {
      title: "nosso time",
    },
    teamMember: {
      diego: {
        name: "Diego Camarinha",
        bio: "Programador desde 2007, possui bacharelado e mestrado em ciência da computação pelo IME - USP. Tem experiência em projetos de desenvolvimento e análise de desempenho de sistemas web.",
      },
      rafael: {
        name: "Rafael Manzo",
        bio: "Começou a programar em 2008 e hoje é bacharel e mestre em ciência da computação pelo IME - USP. Atua em projetos que vão desde lojas online até processamento de imagens de ressonância magnética.",
      },
      eduardo: {
        name: "Eduardo Araújo",
        bio: "Graduando em Física pela Universidade de São Paulo. Tem experiência em projetos web, desenvolvimento de jogos, automação de processos e contribuições em projetos de software livre.",
      },
      heitor: {
        name: "Heitor Reis",
        bio: "Bacharel e mestre em Ciência da Computação pelo IME - USP. Atua no desenvolvimento de aplicativos web com foco em Rails, e modelos probabilísticos para aprendizagem de máquina (Sum-Product Networks).",
      },
      andre: {
        name: "André Silveira",
        bio: "Fazia planilhas de excel simulando campeonatos desde os 10 anos, mas só começou a programar de fato quando entrou na faculdade de ciência da computação em 2014. Descobriu sua paixão por desenvolvimento web 2 anos depois ao conhecer o Rails, e hoje é um grande entusiasta na comunidade Typescript.",
      },
      pedro: {
        name: "Pedro Ligabue",
        bio: "Bacharel em Engeharia Elétrica com ênfase em computação pela POLI-USP e atualmente estuda no mestrado. Trabalha com desenvolvimento web, mas também gosta de programação funcional e ML.",
      },
      wilson: {
        name: "Wilson Mizutani",
        bio: "Doutor em Ciênciada Computação especialista em arquitetura de software. Como co-fundador do USPGameDev, liderou múltiplos projetos com tecnologias de código aberto. Suas principais linguagens de programação são C++ e Lua.",
      },
      arthur: {
        name: "Arthur Vieira",
        bio: "Começou a programar em 2009 por causa de sua paixão por desenvolver jogos, que o levou a fazer bacharelado em ciência da computação pelo IME - USP. Também teve seu primeiro contato com tecnologias de software livre a partir do desenvolvimento de jogos e agora é um adepto de usá-las sempre que possível.",
      },
      rubens: {
        name: "Rubens Gomes",
        bio: "Descobriu o interesse por programação durante o curso de Astronomia, e hoje é formado em Ciência da Computação pelo IME-USP. Durante a graduação trabalhou em projetos de Software Livre relacionados ao desenvolvimento do kernel Linux.",
      },
      gabriel: {
        name: "Gabriel D'Andrea",
        bio: "Sua paixão por jogos o motivou a aprender a programar. Estudou Computação no IME-USP e rapidamente expandiu seu interesse para outras áreas, como desenvolvimento full-stack e computação gráfica. Atualmente suas ferramentas favoritas são SvelteKit, Godot, e Odin.",
      },
      bordoni: {
        name: "Rafael Bordoni",
        bio: "Mestre em matemática pela USP, Rafael ama tecnologia, ciência e arte. Amante de tecnologias código aberto, Rafael acredita que dividir conhecimento é um passo importante para uma sociedade melhor. Começou a programar em 2019 quando começou a fazer seu primeiro video game.",
      },
      lira: {
        name: "Ricardo Lira",
        bio: "Programador de jogos independentes desde 2013 e bacharel em ciência da computação pelo IME-USP. Apaixonado pelo desenvolvimento de software livre, aplicações interativas, lógica e design de experiência.",
      },
      sakayan: {
        name: "Arthur Sakayan",
        bio: "Começou a programar em 2014, quando entrou no curso técnico de informática. Bacharel em Ciência da Computação no IME-USP, possui interesse em várias áreas da Computação e está sempre disposto a atualizar seus conhecimentos. Possui afinidade nas áreas de Matemática e Estatística.",
      },
    },
    contact: {
      title: "contato",
      description:
        "Quer saber mais? <br /> Envie um e-mail para {email} ou deixe uma mensagem abaixo. Vamos adorar ajudar você!",
      submit: "enviar",
      concept_phrase_i: "Quer saber como podemos transformar o seu negócio?",
      concept_phrase_ii:
        "Entre em contato e confira as nossas soluções digitais.",
    },
    contactForm: {
      name: "nome",
      email: "e-mail",
      message: "mensagem",
    },
    navbarLandingPage: {
      contact: "Solicitar avaliação",
    },
    landingPageSection: {
      independent: {
        title: "Análise independente",
        description_i:
          "Por melhor que seja o profissional desenvolvendo o seu software, sabemos que existe um apego pelo próprio trabalho",
        description_ii:
          "Realizamos uma avaliação totalmente imparcial, fornecendo informações sobre o real estado do seu software, gerando assim mais confiança no seu produto.",
      },
      experts: {
        title: "Produzido por especialistas",
        description_i:
          "Contamos com um time de desenvolvedores experientes e qualificados, que vão avaliar de forma personalizada o seu produto.",
        description_ii: "",
      },
      confidential: {
        title: "Sua propriedade intelectual protegida",
        description_i:
          "Para fornecer o parecer técnico, precisamos estudar o seu software. Temos o compromisso de armazenar todo o material recebido de forma encriptada, além de assinarmos um termo de confiabilidade para garantir o sigilo e proteção.",
        description_ii: "",
      },
    },
  },
  views: {
    index: {
      title: "Irya Solutions - Desenvolvimento de Produtos",
    },
    "service-title": {
      prototyping: "Irya Solutions - Serviços de Prototipagem",
      development: "Irya Solutions - Serviços de Desenvolvimento",
      deployment: "Irya Solutions - Serviços de Entrega automatizada",
      maintanance: "Irya Solutions - Serviços de Manutenção",
      monitoring: "Irya Solutions - Serviços de Monitoramento",
      technical_analysis: "Irya Solutions - Serviços de Análise Técnica",
      bug_fixes: "Irya Solutions - Serviços de Correção de Erros",
      improvements: "Irya Solutions - Serviços de Melhoramentos",
      features: "Irya Solutions - Serviços de Novas Funcionalidades",
    },
    "service-description": {
      prototyping:
        "Junto com os nossos nossos clientes desenvolvemos os protótipos (também conhecidos como MVP - Minimal Viable Product), para que suas ideias sejam validadas de forma rápida e com baixo custo.",
      development:
        "Desenvolvemos o produto de forma colaborativa com o cliente e através de entregas constantes, fornecendo uma visão contínua do trabalho realizado. Assim, nossos clientes percebem o retorno de seus investimentos do início ao fim do projeto.",
      deployment:
        "Utilizando ferramentas de automação, criamos ambientes de staging e produção 100% definidos em código. Isso proporciona várias vantagens aos nossos clientes: garantir que toda a infraestrutura esteja devidamente documentada; garantir a manutenibilidade; receber continuamente novas versões em staging; e conseguir enviar a versão mais recente de staging para produção com um único clique.",
      maintenance:
        "Oferecemos a nossos clientes a manutenção necessária para garantir a correção de qualquer falha encontrada. Mantemos o sistema atualizado e continuamente monitoramos seu funcionamento para garantir que tudo está sendo executado da forma correta.",
      monitoring:
        "Realizar verificações periódicas de saúde do seu aplicativo, configurar alertas de alto uso e relatórios automatizados detalhados de erros tornam os sistemas dos nossos clientes mais resilientes e também fornecem uma resposta mais rápida a problemas inesperados, com notificações em tempo real para a equipe.",
      technical_analysis:
        "O software é um dos maiores investimentos que as empresas fazem hoje em dia. Portanto, é importante avaliar a qualidade do sistema entregue de forma eficaz. Por melhor que sejam os profissionais que trabalham no seu software, há um apego ao próprio trabalho. Nossa equipe de desenvolvimento experiente e qualificada fará uma avaliação completamente imparcial, fornecendo informações sobre o estado real do seu software. Dessa forma, você e seus investidores terão mais confiança em seu produto.",
      bug_fixes:
        "Até mesmo os melhores desenvolvedores cometem erros ou não esperam que seu código seja usado de certas maneiras. Correções de bugs fazem parte do desenvolvimento de software e do seu ciclo de vida. Por isso fornecemos aos nossos clientes, mesmo que seu sistema não tenha sido desenvolvido por nós, uma metodologia que corrige os problemas e, também, reduz a probabilidade de regressão por meio da criação de testes automatizados.",
      improvements:
        "Após o lançamento do seu produto e obter sucesso com ele, é inevitável começar a receber solicitações dos usuários para pequenas melhorias. Mesmo que não tenhamos desenvolvido originalmente o sistema, trabalhamos em conjunto com nossos clientes para tornar esses aprimoramentos do que já existe uma realidade, ao mesmo tempo em que garantimos que o sistema continue funcionando conforme o esperado.",
      features:
        "Além das melhorias, o sucesso dos sistemas de nossos clientes também atrai novos usuários com solicitações de novos recursos. Mesmo que não tenhamos desenvolvido originalmente o sistema, trabalhamos em conjunto com eles para desenvolver esses novos recursos, garantindo ao mesmo tempo que o sistema continue funcionando conforme o esperado.",
    },
    "page-not-found": {
      error: "A página que você procura não existe",
      click: "Clique",
      here: "aqui",
      message: "para ir para a página principal",
    },
    "case-description": {
      pae: "PAE",
      vetor: "VETOR BRASIL",
      ampere: "AMPERE",
    },
    "contact-response": {
      success:
        "Seu contato foi enviado com sucesso. Em breve retornamos no e-mail indicado!",
      failed:
        "Houve algum problema no envio do seu contato. Por favor, tente novamente. Se o problema persistir, por favor envie um e-mail para {email}",
      click: "Clique",
      here: "aqui",
      message: "para voltar à página principal",
    },
    "technical-advice": {
      title: "Precisa de um parecer técnico sobre o seu software?",
      subtitle: "A gente pode te ajudar!",
      description:
        "O investimento em software é um dos maiores feitos pelas empresas nos dias de hoje. Por isso, é muito importante que a qualidade do sistema entregue seja avaliada de forma efetiva.",
      contactDescription:
        "Preencha o formulário abaixo e solicite sua avaliação!",
      contact: "Solicitar avaliação",
      footerContactText:
        "Descubra o real estado do seu software e proteja seu produto",
      footerContactButton: "Solicitar avaliação",
    },
  },
};
