<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
html {
  scroll-behavior: smooth;
  width: 100%;
  height: calc(100% - 90px);
}

body {
  width: 100%;
  height: 100%;
}

#app {
  font-family: "Roboto", "Oswald", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 90px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  font: normal normal 600 28px/28px "Oswald", sans-serif;
  letter-spacing: 0px;
}

.subtitle {
  font: normal normal 600 18px/18px "Oswald", sans-serif;
}

.description {
  font: normal normal normal 18px/18px "Roboto", serif;
}

.text {
  font: normal normal normal 14px/14px "Roboto", serif;
}

@media (min-width: 992px) {
  .title {
    font: normal normal 600 38px/64px "Oswald", sans-serif;
  }

  .subtitle {
    font: normal normal 600 24px/38px "Oswald", sans-serif;
  }

  .description {
    font: normal normal normal 24px/30px "Roboto", serif;
  }

  .text {
    font: normal normal normal 18px/30px "Roboto", serif;
  }
}

@media (min-width: 1600px) {
  .title {
    font: normal normal 600 64px/64px "Oswald", sans-serif;
  }

  .subtitle {
    font: normal normal 600 40px/64px "Oswald", sans-serif;
  }

  .description {
    font: normal normal normal 40px/50px "Roboto", serif;
  }

  .text {
    font: normal normal normal 30px/50px "Roboto", serif;
  }
}
</style>
