export function startLocale() {
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  if (!navigatorLocale) {
    return "pt";
  }

  return navigatorLocale.trim().split(/[-_]/)[0];
}
