export default {
  hello: "Hello",
  components: {
    navbar: {
      about: "about",
      services: "services",
      projects: "projects",
      team: "our team",
      contact: "contact",
    },
    cover: {
      concept_phrase_i: "Building software",
      concept_phrase_ii: "delivering digital experiences",
    },
    about: {
      title: "about us",
      description_i:
        "We are an IT consulting and technological product development company that will expand and transform your business with a complete package of customized services, excellently tailored to market needs.",
      description_ii:
        "Creation of websites, applications, systems, and software in general, focusing on a simple, secure, flexible, and efficient interaction for all sizes and sectors. In addition to preventative maintenance, changes and adaptations to optimize and guarantee quality.",
    },
    principles: {
      title: "our values",
      excellence_title: "Excellence",
      excellence_text:
        "We deliver practical, high-quality digital products, with a guarantee.",
      commitment_title: "Commitment & Security",
      commitment_text:
        "We engage and involve ourselves to the fullest to honor deliveries, safely and exceeding service expectations.",
      colab_work_title: "Collaboration & Creativity",
      colab_work_text:
        "We participate in all stages of the project, discovering the best technological solutions for customer objectives together with them.",
    },
    services: {
      title: "services",
      description:
        "We offer a complete package for our clients' software including internal systems, external systems, applications, websites, or other possibilities. We provide full support from the conception and development of the idea to deployment on servers, monitoring stability, maintenance, and future improvements.",
      development: "development",
      prototyping: "prototyping",
      deployment: "deployment",
      maintenance: "maintenance",
      monitoring: "monitoring",
      technical_analysis: "technical analysis",
      bug_fixes: "fixes",
      improvements: "improvements",
      features: "new features",
    },
    customers: {
      title: "who we've transformed",
    },
    cases: {
      title: "featured projects",
      vetor:
        "Management and development of systems related to individuals selection for government roles",
      ampere: "User's experience focused interface",
      kinoforum: "Film collection management system",
    },
    caseDescriptionSection: {
      challenge: {
        title: "Challenge",
        kinoforum:
          "Taking over the codebase initiated by another team in 2011 to fix frequent errors, update the technologies in use, reduce infrastructure costs, and add new functionalities to support the São Paulo International Short Film Festival.",
        vetor:
          "Take care of infrastructure, monitor resources, keep everything running smoothly, add new features, and develop new systems within its business environment.",
        ampere: `Starting from the current system, which has been in use by users for years, create a new graphical interface that maintains what users already are used to, while also bringing improvements to the overall experience<ul>
            <li>visuals</li>
            <li>ease of use</li>
            <li>loading speed</li>
            <li>and so forth</li>
        </ul>`,
      },
      results: {
        title: "Results",
        vetor: `Throughout our time working with this client, we've made significant advancements in their system, among which the mentioned points stand out<ul>
           <li>Launch of three new internal products to support candidate selection</li>
           <li>Optimization of infrastructure resources, resulting in a significant cost reduction</li>
           <li>Flow alterations</li>
           <li>And error corrections resulting in a significant decrease in support tickets, relieving operational strain and enhancing platform stability</li>
         </ul>`,
        ampere:
          "Along with this redesign process, we provided Ampere and its clients not only a better experience for existing functionalities but also introduced new ones as they were prioritized. The work will continue consistently to ensure that other planned new functionalities get delivered.",
        kinoforum:
          "The maintenance of mature systems is an ongoing effort. Since 2019, we've been working with Kinofórum, and today, we can say that we have a stable, updated system with cloud infrastructure costs kept to the minimum required for its proper functioning.",
      },
      solution: {
        title: "Solution",
        vetor: `To attend our client's needs, our decisions were made to provide:<ul>
          <li>Automation of repetitive processes</li>
          <li>Systematic monitoring of resources and failures</li>
          <li>Dynamics for understanding new requirements for both existing products and new products, and implementation of infrastructure</li>
          <li>Database modeling</li>
          <li>Back end and front end</li>
        </ul>`,
        ampere: `In partnership with a user experience designer who conducted interviews with users from all the system products combining with feedback from Ampere's team, all screens were redesigned, presented, and adjusted based on their feedback until deemed ready for development.
          <br /> <br />
          As the new screens are approved, our development team promptly communicates to the Ampere team the necessary changes in the data formats they provide (APIs). We then begin development immediately, and once completed, we deliver it to the testing environment for the final review by the Ampere team and the designer.`,
        kinoforum: `We conducted a gradual process of structural improvements aligned with the delivery of new functionalities meeting the business needs.
          <br /> <br />
          This process involved the creation of automated tests to ensure there won't be regressions of resolved issues, and that new functionalities remain working, along with a comprehensive analysis of all infrastructure costs. Additionally, to ensure data security and system stability, there was a gradual update of all technologies being utilized.`,
      },
    },
    caseDescriptionTechnologies: {
      title: "We use state-of-the-art technology:",
    },
    team: {
      title: "our team",
    },
    teamMember: {
      diego: {
        name: "Diego Camarinha",
        bio: "Programmer since 2007, he has a bachelor's and master's degree in computer science from IME - USP. He has experience in development projects and performance analysis of web systems.",
      },
      rafael: {
        name: "Rafael Manzo",
        bio: "He started programming in 2008 and today he has a bachelor's and master's degree in computer science from IME - USP. He works on projects ranging from online stores to processing magnetic resonance images.",
      },
      eduardo: {
        name: "Eduardo Araújo",
        bio: "Graduating in Physics at the University of São Paulo. He has experience in web projects, game development, process automation and contributions to free software projects.",
      },
      heitor: {
        name: "Heitor Reis",
        bio: "Bachelor and master of Computer Science from IME - USP. Acts in the development of web applications with a focus on Rails, and probabilistic models for machine learning (Sum-Product Networks).",
      },
      andre: {
        name: "André Silveira",
        bio: "Playing with excel spreadsheets simulating championships since 10 years old, but only really started programming when he entered the computer science course in 2014. He discovered his passion for web development 2 years later when he met Rails, and today he is a great enthusiast in the Typescript community.",
      },
      pedro: {
        name: "Pedro Ligabue",
        bio: "BSc in Electrical Engineering with emphasis on computing from POLI-USP and currently studying for a master's degree. Works with web development, but also enjoys functional programming and ML.",
      },
      wilson: {
        name: "Wilson Mizutani",
        bio: "PhD in computer science, specializes in software architecture. As a co-founder of USPGameDev, led multiple projects using a variety of open-source technologies. His main programming languages are C++ and Lua.",
      },
      arthur: {
        name: "Arthur Vieira",
        bio: "Started programming in 2009 because of his passion for developing games, which led him to pursue a bachelor's degree in Computer Science from IME - USP. Also got in touch with open source technologies from game development and is now a great proponent of using them whenever possible.",
      },
      rubens: {
        name: "Rubens Gomes",
        bio: "Developed their interest in programming while studying Astronomy, and now has a bachelor's degree in Computer Science from IME-USP. During their time in university they worked on Free Software projects relating to Linux kernel development.",
      },
      gabriel: {
        name: "Gabriel D'Andrea",
        bio: "His passion for games motivated him to learn to program. He studied Computer Science at IME-USP and quickly expanded his interests into other areas, such as full-stack development and computer graphics. His current favorite tools are SvelteKit, Godot, and Odin.",
      },
      bordoni: {
        name: "Rafael Bordoni",
        bio: "Master in Mathematics from USP, Rafael loves technology, science, and art. A lover of open-source technologies, Rafael believes that sharing knowledge is an important step for a better society. He started programming in 2019 when he began creating his first video game.",
      },
      lira: {
        name: "Ricardo Lira",
        bio: "Independent game developer since 2013 and Bachelor in Computer Science from IME-USP. Passionate about free software development, interactive applications, logic, and experience design.",
      },
      sakayan: {
        name: "Arthur Sakayan",
        bio: "Started programming in 2014, when he started his technical course in computer science. With a Bachelor's degree in Computer Science from IME-USP, he is interested in various areas of Computing and always willing to update his knowledge. He has a strong affinity in the areas of Mathematics and Statistics.",
      },
    },
    contact: {
      title: "contact",
      description:
        "Want to know more? <br /> Send an email to {email} or leave us a message below. We will love to help you!",
      submit: "send",
      concept_phrase_i:
        "Do you want to know how we can transform your business?",
      concept_phrase_ii: "Get in touch and check out our digital solutions.",
    },
    contactForm: {
      name: "name",
      email: "email",
      message: "message",
    },
    navbarLandingPage: {
      contact: "Request evaluation",
    },
    landingPageSection: {
      independent: {
        title: "Independent analysis",
        description_i:
          "However good the professionals working on your software are, there is an attachment for their own work",
        description_ii:
          "We make an evaluation completely unbiased, provinding information about the real state of your software. That way you and your investors will have more confidence about your product.",
      },
      experts: {
        title: "Evaluated by experts",
        description_i:
          "Irya Solutions has an experient and skilled development team that will assess in a customized way your product.",
        description_ii: "",
      },
      confidential: {
        title: "Protected intellectual property",
        description_i:
          "To produce the technical advice we need to study your software code. We commit ourselves to encrypt all received material and to sign a confidentiality agreement to guarantee our commitment.",
        description_ii: "",
      },
    },
  },
  views: {
    index: {
      title: "Irya Solutions - Product Development",
    },
    "service-title": {
      prototyping: "Irya Solutions - Prototyping Services",
      development: "Irya Solutions - Development Services",
      deployment: "Irya Solutions - Deployment Services",
      maintenance: "Irya Solutions - Maintenance Services",
      monitoring: "Irya Solutions - Monitoring Services",
      technical_analysis: "Irya Solutions - Techinal Analysis Services",
      bug_fixes: "Irya Solutions - Bug Fixing Services",
      improvements: "Irya Solutions - Improvements Services",
      features: "Irya Solutions - New Features Services",
    },
    "service-description": {
      prototyping:
        "Together with our customers, we developed prototypes (also known as MVP - Minimal Viable Product), so that your ideas can be validated quickly and at low cost.",
      development:
        "We develop the product collaboratively with the customer and through constant deliveries, providing a continuous view of the work performed. Thus, our customers perceive the return on their investments from the beginning to the end of the project.",
      deployment:
        "Employing automation tools, we create staging and production environments 100% defined in code. This gives our customers multiple advantages: making sure that all its infrastructure is properly documented; ensure maintainability; continuously receive new versions in staging; and have the power to send the latest staging version to production with a single click.",
      maintenance:
        "We offer our customers the necessary maintenance to guarantee the correction of any failure found. We keep the system up to date and continuously monitor its operation to ensure that everything is running smoothly.",
      monitoring:
        "Performing periodic application health checks, setting high-usage alerts, and detailed automated error reports make our customers systems more resilient and also provides faster response to unexpected issues with real time notifications to the team.",
      technical_analysis:
        "Software is one of the biggest investments companies make nowadays. Therefore, it's important to assess the quality of the delivered system in an effective way. However good the professionals working on your software are, there is an attachment for their own work. Our seasoned and skilled development team will make an evaluation completely unbiased, providing information about the real state of your software. That way you and your investors will have more confidence about your product.",
      bug_fixes:
        "Even the best developers make mistakes or don't expect their code to be used in certain ways. Bug fixes are part of software development and its lifecycle. Thus, we provide for our customers, even if their system was not developed by us, a methodology that corrects the issues and, also, reduce the probability of regression by creating automated tests.",
      improvements:
        "After launching your product and making success with, it is inevitable to start receiving user requests for small improvements. Even if we did not originally develop the system, we work together with our customers to make these enhancements to what already exists a reality while still making sure the system continues to work as expected.",
      features:
        "In addition to improvements, the success of our customers systems also brings new users with requests for new features. Even if we did not originally develop their system, we work together with them to develop these new features while still making sure the system continues to work as expected.",
    },
    "page-not-found": {
      error: "The page you are looking for doesn't exist",
      click: "Click",
      here: "here",
      message: "to go to the homepage",
    },
    "case-description": {
      vetor: "VETOR BRASIL",
      interscity: "INTERSCITY",
      kinoforum: "KINOFÓRUM",
      ampere: "AMPERE",
    },
    "contact-response": {
      success:
        "Your contact has been sent successfully. We will be back to you soon!",
      failed:
        "There was a problem sending your contact. Please try again. If the problem persists, please send an email to {email}",
      click: "Click",
      here: "here",
      message: "to go back to the homepage",
    },
    "technical-advice": {
      title: "Do you need a technical advice for your software?",
      subtitle: "We can help you!",
      description:
        "Software is one of the biggest investments companies make nowadays. Therefore, it's important to assess the quality of the delivered system in an effective way.",
      contactDescription: "Fill the form below and request your evaluation!",
      contact: "Request evaluation",
      footerContactText:
        "Discover your software true state and protect your product",
      footerContactButton: "Request evaluation",
    },
  },
};
